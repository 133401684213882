<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <!-- kriterij iskanja -->
            <!--<v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md10 lg10 xl8>
                    <v-card>
                        <v-card-title>
                            <v-icon left>mdi-car</v-icon>
                                    <span class="subtitle-1 font-weight-regular application--card--title">Prehodi (dvig - spust zapornic)</span>
                                    <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('card1')">
                                    <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                        </v-card-title>
                        <v-divider v-show="card1"></v-divider>
                        <v-card-text v-show="card1">
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('vh1_dvig')" :disabled="loading1 || disabled" :loading="loading1" large class="dvig" block>Vhod dvig</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('vh1_spust')" :disabled="loading2 || disabled" :loading="loading2" large class="spust" block>Vhod spust</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('vh1_stalni_dvig')" :disabled="loading3 || disabled" :loading="loading3" large class="stalni_dvig" block>Vhod stalni dvig</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                        <v-divider v-show="card1"></v-divider>
                        <v-card-text v-show="card1">
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('izh1_dvig')" :disabled="loading4 || disabled" :loading="loading4" large class="dvig" block>Izhod dvig</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('izh1_spust')" :disabled="loading5 || disabled" :loading="loading5" large class="spust" block>Izhod spust</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send('izh1_stalni_dvig')" :disabled="loading6 || disabled" :loading="loading6" large class="stalni_dvig" block>Izhod stalni dvig</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>-->

            <!-- vhod -->
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md10 lg10 xl8>
                    <v-card>
                            <v-card-title>
                                <v-icon left>mdi-arrow-right-circle-outline</v-icon>
                                        <span class="subtitle-1 font-weight-regular application--card--title">Vhod</span>
                                        <v-spacer></v-spacer>
                                        <!--<v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown" v-if="vhodTimestampText">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon style="margin-right:5px;" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                                            </template>
                                            <span>Čas podosobitve: {{ vhodTimestampText }}</span>
                                        </v-tooltip>-->
                                    <v-btn icon @click="card3 = !card3">
                                        <v-icon>{{ card3 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card3"></v-divider>
                            <!--<v-card-text v-show="card3">
                                <div>
                                    <v-list v-if="vhod_pozicija_zapornice != null && vhod_stalni_dvig != null">
                                        <v-list-item>
                                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                                <v-icon :color="vhodPozicijaZaporniceColor">mdi-boom-gate</v-icon>
                                            </v-list-item-icon>
                                    
                                            <v-list-item-content >
                                                <v-list-item-title class="body-2 mt-2" >Pozicija zapornice</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-chip
                                                class=""
                                                :color="vhodPozicijaZaporniceColor"
                                                text-color="white"
                                                >
                                                    <v-avatar left>
                                                        <v-icon>{{ vhodPozicijaZaporniceIcon }}</v-icon>
                                                    </v-avatar>
                                                    {{ vhodPozicijaZaporniceText}}
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>-
                                        <v-list-item>
                                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                                <v-icon :color="vhodStalniDvigColor">mdi-boom-gate-up</v-icon>
                                            </v-list-item-icon>
                                    
                                            <v-list-item-content >
                                                <v-list-item-title class="body-2 mt-2" >Stalni dvig zapornice</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-chip
                                                class=""
                                                :color="vhodStalniDvigColor"
                                                text-color="white"
                                                >
                                                    <v-avatar left>
                                                        <v-icon>{{ vhodStalniDvigIcon }}</v-icon>
                                                    </v-avatar>
                                                    {{ vhodStalniDvigText}}
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-card-text>-->
                            <v-app-bar
                                v-show="card3"
                                flat
                                color="white"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-car</v-icon>
                                <v-toolbar-title class="body-2">Prehod (dvig - spust zapornice)</v-toolbar-title>
                            </v-app-bar>
                            <v-divider v-show="card3"></v-divider>
                            <v-card-text v-show="card3">
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send('vh1_dvig')" :disabled="loading1 || disabled" :loading="loading1" large class="dvig" block>Vhod dvig</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send('vh1_spust')" :disabled="loading2 || disabled" :loading="loading2" large class="spust" block>Vhod spust</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send('vh1_stalni_dvig')" :disabled="loading3 || disabled" :loading="loading3" large class="stalni_dvig" block>Vhod stalni dvig</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                         </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>

            <!-- izhod -->
            <!--<v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md10 lg10 xl8>
                    <v-card>
                            <v-card-title>
                                <v-icon left>mdi-arrow-left-circle-outline</v-icon>
                                        <span class="subtitle-1 font-weight-regular application--card--title">Izhod</span>
                                        <v-spacer></v-spacer>-->
                                        <!--<v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown" v-if="izhodTimestampText">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon style="margin-right:5px;" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                                            </template>
                                            <span>Čas podosobitve: {{ izhodTimestampText }}</span>
                                        </v-tooltip>-->
                                    <!--<v-btn icon @click="card4 = !card4">
                                        <v-icon>{{ card4 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card4"></v-divider>-->
                            <!--<v-card-text v-show="card4">
                                <div>
                                    <v-list v-if="izhod_pozicija_zapornice != null && vhod_stalni_dvig != null"> 
                                        <v-list-item>
                                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                                <v-icon :color="izhodPozicijaZaporniceColor">mdi-boom-gate</v-icon>
                                            </v-list-item-icon>
                                    
                                            <v-list-item-content >
                                                <v-list-item-title class="body-2 mt-2" >Pozicija zapornice</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-chip
                                                class=""
                                                :color="izhodPozicijaZaporniceColor"
                                                text-color="white"
                                                >
                                                    <v-avatar left>
                                                        <v-icon>{{ izhodPozicijaZaporniceIcon }}</v-icon>
                                                    </v-avatar>
                                                    {{ izhodPozicijaZaporniceText }}
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                                <v-icon :color="izhodStalniDvigColor">mdi-boom-gate-up</v-icon>
                                            </v-list-item-icon>
                                    
                                            <v-list-item-content >
                                                <v-list-item-title class="body-2 mt-2" >Stalni dvig zapornice</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-chip
                                                class=""
                                                :color="izhodStalniDvigColor"
                                                text-color="white"
                                                >
                                                    <v-avatar left>
                                                        <v-icon>{{ izhodStalniDvigIcon }}</v-icon>
                                                    </v-avatar>
                                                    {{ izhodStalniDvigText }}
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-card-text>-->
                            <!--<v-divider v-show="card4"></v-divider>
                            <v-app-bar
                                v-show="card4"
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-car</v-icon>
                                <v-toolbar-title class="body-2">Prehod (dvig - spust zapornice)</v-toolbar-title>
                            </v-app-bar>
                            <v-divider v-show="card4"></v-divider>
                            <v-card-text v-show="card4">
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send('izh1_dvig')" :disabled="loading4 || disabled" :loading="loading4" large class="dvig" block>Izhod dvig</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send('izh1_spust')" :disabled="loading5 || disabled" :loading="loading5" large class="spust" block>Izhod spust</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send('izh1_stalni_dvig')" :disabled="loading6 || disabled" :loading="loading6" large class="stalni_dvig" block>Izhod stalni dvig</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                         </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>-->
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate } from '@/helpers/utilities'

export default {


    data: () => ({
        
        card1: true,
        card2: true,
        card3: true,
        card4: true,
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
        loading5: false,
        loading6: false,
        disabled: false,

        interval: null,
        devices: [],

        vhod_pozicija_zapornice: null,
        vhod_stalni_dvig: null,
        vhod_status_datum: null,
        vhod_status_cas: null,

        izhod_pozicija_zapornice: null,
        izhod_stalni_dvig: null,
        izhod_status_datum: null,
        izhod_status_cas: null
    }),

    watch: {
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        vhodPozicijaZaporniceText() {
            if(this.vhod_pozicija_zapornice == 0) {
                return 'Odprta'
            }

            return 'Zaprta'
        },

        vhodStalniDvigText() {
            if(this.vhod_stalni_dvig == 0) {
                return 'Ni aktiven'
            }

            return 'Aktiven'
        },

        vhodPozicijaZaporniceIcon() {
            if(this.vhod_pozicija_zapornice == 0) {
                return 'mdi-check'
            }

            return 'mdi-cancel'
        },

        vhodStalniDvigIcon() {
            if(this.vhod_stalni_dvig == 0) {
                return 'mdi-cancel'
            }

            return 'mdi-check'
        },

        vhodPozicijaZaporniceColor() {
            if(this.vhod_pozicija_zapornice == 0) {
                return '#4ba36b' // zelena
            }

            return '#ce5959' // rdeca
        },

        vhodStalniDvigColor() {
            if(this.vhod_stalni_dvig == 0) {
                return '#ce5959'
            }

            return '#4ba36b'
        },

        vhodTimestampText() {
            if(this.vhod_status_datum != null && this.vhod_status_cas != null) {
                return this.vhod_status_datum + " " + this.vhod_status_cas
            }

            return ''
        },

        izhodPozicijaZaporniceText() {
            if(this.izhod_pozicija_zapornice == 0) {
                return 'Odprta'
            }

            return 'Zaprta'
        },

        izhodStalniDvigText() {
            if(this.izhod_stalni_dvig == 0) {
                return 'Ni aktiven'
            }

            return 'Aktiven'
        },

        izhodPozicijaZaporniceIcon() {
            if(this.izhod_pozicija_zapornice == 0) {
                return 'mdi-check'
            }

            return 'mdi-cancel'
        },

        izhodStalniDvigIcon() {
            if(this.izhod_stalni_dvig == 0) {
                return 'mdi-cancel'
            }

            return 'mdi-check'
        },

        izhodPozicijaZaporniceColor() {
            if(this.izhod_pozicija_zapornice == 0) {
                return '#4ba36b' // zelena
            }

            return '#ce5959' // rdeca
        },

        izhodStalniDvigColor() {
            if(this.izhod_stalni_dvig == 0) {
                return '#ce5959'
            }

            return '#4ba36b'
        },

        izhodTimestampText() {
            if(this.izhod_status_datum != null && this.izhod_status_cas != null) {
                return this.izhod_status_datum + " " + this.izhod_status_cas
            }

            return ''
        },

    },

    methods: {

        toggleCardContent(card) {
            if(card === 'card1') {
                this.card1 = !this.card1
            }
        },

        send(data) {
            var vm = this
            let payload = {
                topic:"parking-barriers",
                payload: data
            };

            let snackbarText = ''

            if(data == 'vh1_dvig') {
                this.loading1 = true
                snackbarText = "Dvig vhodne zapornice je uspel."
            }

            if(data == 'vh1_spust') {
                this.loading2 = true
                snackbarText = "Spust vhodne zapornice je uspel."
            }

            if(data == 'vh1_stalni_dvig') {
                this.loading3 = true
                snackbarText = "Stalni dvig vhodne zapornice je uspel."
            }

            if(data == 'izh1_dvig') {
                this.loading1 = true
                snackbarText = "Dvig izhodne zapornice je uspel."
            }

            if(data == 'izh1_spust') {
                this.loading2 = true
                snackbarText = "Spust izhodne zapornice je uspel."
            }

            if(data == 'izh1_stalni_dvig') {
                this.loading3 = true
                snackbarText = "Stalni dvig izhodne zapornice je uspel."
            }


            this.disabled = true;
            vm.$store.dispatch('PUBLISH', payload)
            .then(response => {
                window.console.log(response.data)
                vm.$store.commit('snackbarDisplay', false)
                vm.$store.commit('snackbarMode', 'multi-line')
                vm.$store.commit('snackbarText', snackbarText)
                vm.$store.commit('snackbarTimeout', 7000)
                vm.$store.commit('snackbarDisplay', true)
            })
            .catch(error => {
                vm.$store.commit('snackbarDisplay', false)
                vm.$store.commit('snackbarMode', 'multi-line')
                vm.$store.commit('snackbarText', "Pri upravljanju zapornice je prišlo do napake. Prosimo poskusite ponovno.")
                vm.$store.commit('snackbarTimeout', 7000)
                vm.$store.commit('snackbarDisplay', true)
            })
            .finally(() => {
                this.loading1 = false
                this.loading2 = false
                this.loading3 = false
                this.loading4 = false
                this.loading5 = false
                this.loading6 = false
                setTimeout(() => {
                    vm.disabled = false;
                }, 4000)
            })
        },

        fetchDevices() {

            this.$store.commit('SET_PROGRESS', true)
            this.$store.dispatch('DEVICES')
            .then(response =>  {
                //window.console.log(response.data)
                this.prepareDeviceData(response.data)
            })
            .catch(error => {
                window.console.error("### ParkingBarriers@fetchDevices ###")
                window.console.log(error)
            })
            .finally(() => {
                this.$store.commit('SET_PROGRESS', false)
            })
        },

        prepareDeviceData(data) {
            if(Array.isArray(data)) {
                data.forEach(device => {
                    if(device.id == 1) {
                        this.vhod_pozicija_zapornice = device.pozicija_zapornice
                        this.vhod_stalni_dvig = device.stalni_dvig
                        this.vhod_status_datum = device.date
                        this.vhod_status_cas = device.time
                    }

                    if(device.id == 2) {
                        this.izhod_pozicija_zapornice = device.pozicija_zapornice
                        this.izhod_stalni_dvig = device.stalni_dvig
                        this.izhod_status_datum = device.date
                        this.izhod_status_cas = device.time
                    }
                })
            }
        }



        
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
        //this.fetchDevices();
        /*this.interval = setInterval(() => {
            this.fetchDevices();
        }, 12000)*/

    },

    beforeDestroy() {
        clearInterval(this.interval)
    },

    destroyed() {
        
    }
}

</script>

<style scoped>
    .dvig {
        background: #4ba36b !important;
        color: #fff;
    }

    .spust {
        background: #e79e42 !important;
        color: #fff;
    }

    .stalni_dvig {
        background: #ce5959 !important;
        color: #fff;
    }

</style>